export const BASE_URL = 'https://sixgrams.com/webapi/'
export const  CATEGORY_IMG_URL = "https://sixgrams.com/assets/img/category/";
export const  COUPON_IMG_URL = "https://sixgrams.com/assets/img/coupon/"; 
export const MERCHANT_IMG_URL = "https://sixgrams.com/assets/img/merchant/";
export const  BRANCH_IMG_URL = "https://sixgrams.com/assets/img/branch/";
export const  PACKAGE_IMG_URL = "https://sixgrams.com/assets/img/package/"
export function capitalizeFirstLetter(str) {
    return str !== null ?  str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ') :''; 
  }