import React from 'react'
import './Footer.css'
import { Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
function Footer() {
    const {user} = useSelector(state=>state.user)
    const socials = [
        {
            imgUrl : 'facebook.svg',
            link : 'https://www.facebook.com/sixgraams'
        },        
  
        {
            imgUrl : 'twitter.svg',
            link : 'https://twitter.com/sixgrams_com'
        },
        {
            imgUrl : 'linkedin.svg',
            link : 'https://www.linkedin.com/company/sixgrams/'
        },

    ]
//     https://www.linkedin.com/company/sixgrams/
// https://twitter.com/sixgrams_com
// https://www.pinterest.com/Sixgraams/
    const handleApp = (link)=>{
        window.open(link, '_blank');
    }
   
  return (
    <div className="all-cont">
        <div className="mid-cont">
            <div className="footer-item-container">
                <div className="footer-links">
                    <div className="footer-link-1">
                        <h2>Quick Links</h2>
                        <Link className='footer-links links' style={{textDecoration:'none' , color:'#242424'}} to={'/about'}>
                        <label className='links'>About Sixgrams</label></Link>
                        <Link className='links' to={user?.user_id ? '/profile' : '/sign-in'}><label className='links'>My Account</label></Link>
                        <Link className='links' to={user?.user_id ? '/profile' : '/sign-in'}><label className='links'>Active Coupons</label></Link>
                        <Link className='footer-links links' style={{textDecoration:'none' , color:'#242424'}} to={'/deleteuser'}>
                        <label className='links'>Delete Account</label></Link>  
                              </div>
                    
                    <div className="footer-link-1">
                        <h2>Customer Service</h2>
                        <Link className='links' to={'/contact'}><label className='links'>Contact Us</label></Link>
                        <Link className='footer-links links' style={{textDecoration:'none'}} to={'/faq'}>
                        <label className='links'>FAQs</label></Link>
                    </div>
                </div>
                <div className="footer-socials">
                    <div className="socials">
                        {
                            socials.map((social, index)=>(
                                    <img loading='lazy'  onClick={()=>handleApp(social.link)} key={index} className='img-socials' src={social.imgUrl} alt="" />
                            ))
                        }
                    </div>
                    <label >Download the Sixgrams app for the ultimate shopping experience!</label>
                    <div className="app-btn">
                        <Link ></Link>
                        {/* https://apps.apple.com/pk/app/sixgrams/id6449671040 */}
                        <button className='play' onClick={()=>handleApp('https://play.google.com/store/apps/details?id=com.traxxis.tengrams')}></button>
                        <button className='app' onClick={()=>handleApp('https://apps.apple.com/pk/app/sixgrams/id6449671040')}></button>
                    </div>
                
                </div>

            </div>
            <div className="last-footer">
                <label className='footer-card' >We Accept</label>
                <img loading='lazy'  src="visa.svg" alt="" />
                <img loading='lazy'  src="master.svg" alt="" />
            </div>
                <label className='foot-text'>© 2017-2023 Sixgrams. All Rights Reserved. - User Agreement - <Link className='footer-links links' style={{textDecoration:'none'}} to={'/privacy'}> Privacy Policy </Link></label>
            </div>
    </div>
  )
}

export default Footer