import React , {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { getGroupPackage, undefineGroupPackage } from '../../Redux/actions/packageAction';

// import { Button } from '../Button/Button'
import CountryDropdown from '../CountryDropDown/Countrydropdown'
import './NavBar.css' 
import { useDispatch } from 'react-redux';
import {logOut} from '../../Redux/actions/userAction'
import {toggleGroup} from '../../Redux/actions/packageAction'

import axios from 'axios';
import { BsFillPersonFill } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import { BASE_URL, MERCHANT_IMG_URL } from '../../constants/constants';
import {MaterialUISwitch} from '../MemberSwitch/MemberSwitch'
import { toast } from 'react-hot-toast';

function NavBar({isAuthenticated = false, user}) {
  const [click , setClick] = useState(false)
  const handleClick = ()=> setClick(!click)
  const closeMobileMenu = ()=> setClick(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data , setData] = useState()
  const [focus , setFocus] = useState()
  const [selected , setSelected] = useState(false)
  const [group , setGroup] = useState(false)
  const handleLogOut = () =>{
    dispatch(logOut())
  }

  const handleOnSearch = (e) => {
    const timeoutId = setTimeout( async () => {
      await axios.get(BASE_URL+'search_webapi.php?search='+e.target.value).then((response)=>{
        if(typeof response.data.message !=='string'){
          setData(response.data.message)
        }

      }).catch((error)=>{
        console.log(error);
      })

    }, 500);
  
    return ()=>{
      clearTimeout(timeoutId)
    }

  };

  const handleOnSelect = (m_id)=>{  

      setSelected(true)
      navigate(`/search?m_id=${m_id}`)
      setTimeout(() => {
        setSelected(false)
        setData([])

      }, 2000);
      const inputElement = document.querySelector('.search');
      if (inputElement) {
        inputElement.value = '';
      }
      const inputElement1 = document.querySelector('.search-2');
      if (inputElement1) {
        inputElement1.value = '';
      }
     }
     const handleMember = (e)=>{
      if(!group){
        axios.get(BASE_URL+`memberpackage_webapi.php?user_id=${user?.user_id}`).then((response)=>{
          if(!response.data.status){
            toast.error('You are not member of any group')
          }else{
            dispatch(getGroupPackage(user?.user_id))
            setGroup(true)
            dispatch(toggleGroup(true))   
          }
        })
      }else{
        setGroup(false)
        dispatch(toggleGroup(false))
        dispatch(undefineGroupPackage())
      }   
    }

     


  return (
    <>
    <div style={{height : '27px' , width :'100%',backgroundColor: '#D73A4D', color:'white', fontFamily:'Poppins', fontSize :'12px', textAlign : 'center', alignItems : 'center', display:'flex', justifyContent: 'center' }}>Exclusive Offers , DEALS & COUPONS </div>
    <nav className="navbar">
        <div className="navbar-container">
            <Link style={{ borderRadius:'10px'}} to='/' className= "navbar-logo">
                <img  loading='lazy' className='logo-img' src="logo6.svg" alt="" />
                {/* <h1 style={{color:'#fff', fontSize:'43px'}}>LOGO</h1> */}
            </Link>
            <CountryDropdown/>
            <div className="search-bar">
              <input onBlur={()=>{
                setFocus(false)
                setTimeout(() => {
                  setData([])
                }, 200);
                }} onFocus={()=>{setFocus(true)}} onChange={handleOnSearch} placeholder='Search for coupons and deals here' type="search" className={data && focus ? 'search' :'search-2'}/>
              <div className='result'>
                {data  && (focus || !selected) ?
                  data.slice(0, 10).map((item, index)=>{
                    return <div key={index} onClick={()=> handleOnSelect(item?.m_id)} className="result-wrapper">
                      {/* <span className="result-span">id: {item.m_id}</span> */}
                      <img loading='lazy'  width={'20px'} height={"20px"} src={MERCHANT_IMG_URL+item?.m_logo} alt="" />
                      <span className="result-span"> {item?.m_name}</span>
                    </div>
                  })
               :'' }
              </div>
              </div>
          
           
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
            </div>
            <ul style={{alignItems:'center'}} className={click ? 'nav-menu active' : 'nav-menu'}>
          
          {isAuthenticated?  <>  <li className="nav-item">
                <Link style={{display : 'flex',justifyContent:'center',alignItems:'center',gap :'10px', whiteSpace:'nowrap'}} to='/profile' className='nav-links' onClick={closeMobileMenu}>
                  Hello, {user.user_name} 
                  {/* <img loading='lazy'  src="user2.svg" alt="" /> */}
                </Link>
              </li>
              <li>
                <MaterialUISwitch checked={group}
                icon={<div style={{ borderRadius:"100%", display:'flex', justifyContent:'center', alignItems:'center', padding:'6px' , background:'#fff'}}
                ><BsFillPersonFill fill='#D73A4D'/></div>}
                checkedIcon={<div style={{ borderRadius:"100%", display:'flex', justifyContent:'center', alignItems:'center', padding:'7px' , background:'#fff'}}
                ><HiUserGroup fill='#D73A4D'/></div>} onChange={handleMember} />
              </li>
              <li onClick={handleLogOut} className="nav-item">
                <Link style={{display : 'flex',justifyContent:'center',alignItems:'center',gap :'10px', whiteSpace:'nowrap'}}  className='nav-links' onClick={closeMobileMenu}>
                  Log Out <img src="logout.svg" alt="" />
                </Link>
              </li>
               </>:  <>   <li className="nav-item">
                <Link style={{display : 'flex',justifyContent:'center',alignItems:'center',gap :'10px', whiteSpace:'nowrap'}} to='/allcoupons' className='nav-links' onClick={closeMobileMenu}>
                  Special Offers <img loading='lazy'  src="search.svg" alt="" />
                </Link>
              </li>
              <li className="nav-item">
                <Link style={{display : 'flex',justifyContent:'center',alignItems:'center',gap :'10px', whiteSpace:'nowrap'}} 
                to='/sign-in' 
                className='nav-links' onClick={closeMobileMenu}>
                  Login / SignUp <img  loading='lazy'  src="login.svg" alt="" />
                </Link>
              </li> </>}
         
             
            </ul>
            
        </div>
    </nav>
    </>
  )
}


export default NavBar