import { configureStore } from "@reduxjs/toolkit";
import { BASE_URL} from '../constants/constants'
import { userReducer } from "./reducers/userReducer";
import { packageReducer,  singlePackageReducer } from "./reducers/packageReducer";
import { categoriesReducer, couponReducer, merchantsReducer } from "./reducers/couponReducer";
export const server = BASE_URL
const store = configureStore({
    reducer:{
        user :  userReducer,
        packages :packageReducer,
        coupons : couponReducer,
        merchants : merchantsReducer,
        categories : categoriesReducer,
        single : singlePackageReducer,
    }
})


export default store