import React from 'react';
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';

const countryOptions = [
    { value: 'ae', label: ` AED`, icon: () => <FlagIcon code="AE" style={{width: "21px", height: "11px"}} /> },
//   { value: 'ca', label: 'Canada', icon: () => <FlagIcon code="CA" /> },
//   { value: 'mx', label: 'Mexico', icon: () => <FlagIcon code="MX" /> },
  // Add more countries here
];
const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height:'2vh',
      width:'100px',
      boxShadow: 'none',
      border: 'none',
      borderRadius : '10px',
      fontSize: '10px',
      backgroundColor : '#fff',
      maxHeight:'2vh'
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      fontSize: '10px',
    }),
    singleValue: (provided, state) => ({
      ...provided,

      
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: '0 8px',
      cursor : 'pointer'
      
      
    }),
    flagIcon: {
      width: '30px',
      height: '10px',
    //   marginRight: '8px',
    },
  };
const renderCountryOption = (option) => (
  <div className="country-option">
    <span className="country-icon">{option.icon()}</span>
    <span className="country-label">{option.label}</span>
  </div>
);

const CountryDropdown = () => {
  return (
    <div className="country-dropdown">
    <Select styles={customStyles}
    value={countryOptions[0]}
      options={countryOptions}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      formatOptionLabel={renderCountryOption}
      menuPlacement="bottom"
    />
    </div>
  );
};

export default CountryDropdown;
