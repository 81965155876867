import { createReducer } from "@reduxjs/toolkit";


export const userReducer = createReducer({
    user : JSON.parse(localStorage.getItem('user')),
    error : null,
    isAuthenticated : JSON.parse(localStorage.getItem('user')) ? true : false
},{
    loginRequest : (state )=>{
        state.loading =true
    },
    loginSuccess : (state , action)=>{
        state.loading = false;
        if (action.payload.userdata) {
            state.isAuthenticated = true;
            state.user = action.payload.userdata;
            state.message = `Welcome ${action.payload?.userdata?.user_name} , You have been logged in at Sixgrams.`;
            localStorage.setItem('user', JSON.stringify(state.user));
          } else {
            state.isAuthenticated = false;
            state.error = "Fill all the details"; 
          }
    },
    loginFail : (state , action)=>{
        state.loading =false
        state.isAuthenticated = false 
        state.error = action.payload 
    },
    signUpRequest : (state )=>{
        state.loading =true
    },
    signUpSuccess : (state , action)=>{
        state.loading = false;
        state.message = action.payload.message;
    },
    signUpFail : (state , action)=>{
        state.loading =false
        state.isAuthenticated = false 
        state.error = action.payload 
    },
    logOutRequest : (state )=>{
        state.loading =true
    },
    logOutSuccess : (state , action)=>{
        state.loading = false;
        state.isAuthenticated= false
        localStorage.removeItem('user');
        state.user = null
        state.message = action.payload.message
        
    },
    logOutFail : (state , action)=>{
        state.loading =false
        state.isAuthenticated = true 
        state.error = action.payload
    },
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
})  