import { createReducer } from "@reduxjs/toolkit";

export const couponReducer = createReducer({},{
    getCouponRequest : (state )=>{
        state.loading =true
    },
    getCouponSuccess : (state , action)=>{
        state.loading = false;
        state.coupons = action.payload.message;
        state.response = action.payload.response;
        state.pkg_id = action.payload.package_issuance_id
        state.member = action.payload.member
    },
    getCouponFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    
    removeRedeemedCoupon : (state , action)=>{
        state.coupons= state.coupons.filter(coupon => coupon.coupon_id !== action.payload);
    },
    redeemCouponRequest : (state )=>{
        state.loading =true
    },
    redeemCouponSuccess : (state , action)=>{
        state.loading = false;
        state.redeem = action.payload.message;
        state.status = action.payload.status
    },
    redeemCouponFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    getMerchantCouponRequest : (state )=>{
        state.loading =true
    },
    getMerchantCouponSuccess : (state , action)=>{
        state.loading = false;
        state.merchantCoupon = action.payload.message;
    },
    getMerchantCouponFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    getCategoryCouponRequest : (state )=>{
        state.loading =true
    },
    getCategoryCouponSuccess : (state , action)=>{
        state.loading = false;
        state.categoryCoupon = action.payload.message;
    },
    getCategoryCouponFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    getHistoryCouponRequest : (state )=>{
        state.loading =true
    },
    getHistoryCouponSuccess : (state , action)=>{
        state.loading = false;
        state.history = action.payload.message;
    },
    getHistoryCouponFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.messHistory
    },
    
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
        state.message = null
    }
}) 
export const merchantsReducer = createReducer({},{
    getMerchantsRequest : (state )=>{
        state.loading =true
    },
    getMerchantsSuccess : (state , action)=>{
        state.loading = false;
        state.merchants = action.payload.message;
    },
    getMerchantsFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
}) 
export const categoriesReducer = createReducer({},{
    getCategoriesRequest : (state )=>{
        state.loading =true
    },
    getCategoriesSuccess : (state , action)=>{
        state.loading = false;
        state.categories = action.payload.message;
    },
    getCategoriesFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
}) 