import { createReducer } from "@reduxjs/toolkit";

export const packageReducer = createReducer({
    toggle:false
},{
    getPackagesRequest : (state )=>{
        state.loading =true
    },
    getPackagesSuccess : (state , action)=>{
        state.loading = false;
        state.packages = action.payload.message;
    },
    getPackagesFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
     getMemberRequest : (state )=>{
        state.loading =true
    },
    getMemberSuccess : (state , action)=>{
        state.loading = false;
        state.members = Array.isArray(action.payload.data) ? action.payload.data : [];
    },
    getMemberFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    getAddedMemberRequest : (state )=>{
        state.loading =true
    },
    getAddedMemberSuccess : (state , action)=>{
        state.loading = false;
        state.members =[...state.members, action.payload.message]
    },
    getAddedMemberFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    toggleGroupRequest : (state )=>{
        state.loading =true
    },
    toggleGroupSuccess : (state , action)=>{
        state.loading = false;
        state.toggle = action.payload.toggle
    },
    toggleGroupFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    getGroupPackageRequest : (state )=>{
        state.loading =true
    },
    getGroupPackageSuccess : (state , action)=>{
        state.loading = false;
        state.package2 =  action.payload.data
    },
    getGroupPackageFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
     undefineGroupPackage : (state )=>{
        state.package2 =  null
    },

    
    
    
  
 
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
}) 
export const singlePackageReducer = createReducer({},{
    getReviewRequest : (state )=>{
        state.loading =true
    },
    getReviewSuccess : (state , action)=>{
        state.loading = false;
        state.reviewinit = action.payload.message;
    },
    getReviewFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    getSinglePackageRequest : (state )=>{
        state.loading =true
    },
    getSinglePackageSuccess : (state , action)=>{
        state.loading = false;
        state.one = action.payload.couponmessage;
        state.second = action.payload.merchantmessage;
        state.review = Array.isArray(action.payload.review) ? action.payload.review.reverse() : [];
    },
    getSinglePackageFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
     getPurchaseLinkRequest : (state )=>{
        state.loading =true
    },
    getPurchaseLinkSuccess : (state , action)=>{
        state.loading = false;
        if(action.payload.url){
            state.link = action.payload.url;
        }else{
            state.error = "Package Already Exists. Please Use Existing Coupons"
        }
        
    },
    getPurchaseLinkFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message
    },
    
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
}) 
