import './App.css';
import NavBar from './components/NavBar/NavBar'
import { BrowserRouter as Router,Routes ,Route } from 'react-router-dom';
import React, { useEffect ,lazy,Suspense  } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import toast ,{Toaster} from 'react-hot-toast'
import {ProtectedRoute} from 'protected-route-react'
import {FAQ_CONSTANTS,POLICY_CONSTANTS,ABOUT_CONSTANTS} from './constants/infoConstants'
import Loader from './components/Loader/Loader';


const Merchants = lazy(()=>import('./pages/Merchants'))
const Packages = lazy(()=>import('./pages/Packages'))
const  Trending= lazy(()=>import('./pages/Trending'))
const Home = lazy(()=>import('./pages/Home'))
const Profile = lazy(()=>import('./pages/Profile'))
const PackageDetail = lazy(()=>import('./pages/PackageDetail'))
const Sign = lazy(()=>import('./pages/Sign'))
const CategoryPage = lazy(()=>import('./pages/CategoryPage'))
const MerchantDetail = lazy(()=>import('./pages/merchantDetail'))
const PaymentPage = lazy(()=>import('./pages/PaymentPage'))
const PasswordReset = lazy(()=>import('./pages/PasswordReset'))
const DeleteUser = lazy(()=>import('./pages/DeleteUser'))
const ContactForm = lazy(()=>import('./components/ContactForm/ContactForm'))
const MessagePage = lazy(()=>import('./pages/MessagePage'))
const PrivacyPolicy = lazy(()=>import('./pages/PrivacyPolicy'))
const ForgotPassword = lazy(()=>import('./pages/ForgotPassword'))
const AllCoupons = lazy(()=>import('./pages/AllCoupons'))
const SearchPage = lazy(()=>import('./pages/SearchPage'))


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {

 

  const {isAuthenticated, user , message ,error} =  useSelector(state =>state.user)
  const dispatch = useDispatch()
  useEffect(()=>{
    if(error){
      toast.error(error)
      dispatch({type : 'clearError'})
    }
    if(message){
      toast.success(message)
    
      dispatch({type : 'clearMessage'})
    }


  },[dispatch, error , message])

  
  return (
    <>
    <div className='max-width-container' style={{maxWidth:'1920px', justifySelf: 'center'}}>
       <Router>
          <Suspense fallback={<Loader/>}>
          <ScrollToTop />
              <NavBar isAuthenticated={isAuthenticated} user={user}  />
                <Routes>
                <Route path="/" element={<Home  />} />
                <Route path="/privacy" element={<PrivacyPolicy header={POLICY_CONSTANTS.header} header2={POLICY_CONSTANTS.header2} text={POLICY_CONSTANTS.text}/>} />
                <Route path="/faq" element={<PrivacyPolicy header={FAQ_CONSTANTS.header} header2={FAQ_CONSTANTS.header2} text={FAQ_CONSTANTS.text}/>} />
                <Route path="/about" element={<PrivacyPolicy header={ABOUT_CONSTANTS.header} header2={ABOUT_CONSTANTS.header2} text={ABOUT_CONSTANTS.text}/>} />
                <Route path="/deleteuser" element={<DeleteUser />} />
                <Route path="/contact" element={<ContactForm />} />
                <Route path="/package" element={<PackageDetail />} />
                <Route path="/reset" element={<PasswordReset />} />
                <Route path="/category" element={<CategoryPage />} />
                <Route path="/merchant" element={<MerchantDetail />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/prompt" element={<MessagePage />} />
                <Route path="/allcoupons" element={<AllCoupons />} />
                <Route path="/forgot" element={<ForgotPassword />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/merchants" element={<Merchants />} />
                <Route path="/trending" element={<Trending />} />

                <Route path="/profile" element={ <ProtectedRoute isAuthenticated={isAuthenticated} redirect={'/sign-in'} >
                  <Profile />
                </ProtectedRoute>} />
                <Route path="/payment"  element={ <ProtectedRoute isAuthenticated= {isAuthenticated} redirect={'/'}>
                  <PaymentPage/>
                </ProtectedRoute>} />
                <Route path="/sign-in" element={ <ProtectedRoute isAuthenticated= {!isAuthenticated} redirect={'/'}>
                  <Sign formDetails={false} />
                </ProtectedRoute>} />
                <Route path="/sign-up" element={ <ProtectedRoute isAuthenticated = {!isAuthenticated} redirect={'/'}>
                  <Sign formDetails={true}/>
                </ProtectedRoute>} />
              </Routes>
              <Footer/>
              <Toaster/>
          </Suspense>
       </Router>
  </div>
    </>
  );
}

export default App;
