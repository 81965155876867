import axios from 'axios'
import { BASE_URL } from '../../constants/constants';



export const getPackages = ( ) => async (dispatch)=>{
    try {
        dispatch({type : 'getPackagesRequest'});
        const {data} = await axios.get(BASE_URL+'package_webapi.php' )
        dispatch({type : 'getPackagesSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'getPackagesFail',payload : error.response.data.message});
    }
}
export const getSinglePackage = (id) => async (dispatch)=>{
    try {
        dispatch({type : 'getSinglePackageRequest'});
        const {data} = await axios.post(BASE_URL+'merchant_coupon_webapi.php?package_id='+id )
        dispatch({type : 'getSinglePackageSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'getSinglePackageFail',payload : error.response.data.message});
    }
}
export const getReview = (reviewInput) => async (dispatch)=>{

    try {
        // const {user} = useSelector(state=>state.user)
        dispatch({type : 'getReviewRequest'});
        const {data} = await axios.get(BASE_URL +`insert_review_webapi.php?user_id=${reviewInput.user_id}&review=${reviewInput?.review} &rating=${reviewInput?.rating}&m_id=${reviewInput?.m_id}&name=${reviewInput.name}`)
        dispatch({type : 'getReviewSuccess' , payload : data });

    } catch (error) {
        dispatch({type : 'getReviewFail',payload : error.response.data.message});
    }
}
export const getPurchareLink = (user_id, package_id) => async (dispatch)=>{
    try {
        dispatch({type : 'getPurchaseLinkRequest'});
        const {data} = await axios.post(BASE_URL+`checkout_react_webapi.php`, {user_id, package_id} , {
            headers:{
                'Content-Type': "multipart/form-data"
            }
        })
        dispatch({type : 'getPurchaseLinkSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'getPurchaseLinkFail',payload : error.response.data.message});
    }
}
export const getMembers = (user_id) => async (dispatch)=>{
    try {
        dispatch({type : 'getMemberRequest'});
        const {data} = await axios.post(BASE_URL+`memberlist_webapi.php?user_id=${user_id}`)
        dispatch({type : 'getMemberSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'getMemberFail',payload : error.response.data.message});
    }
}
export const getAddedMember = (user_id) => async (dispatch)=>{

    try {
        dispatch({type : 'getMemberRequest'});
        // const data ={
        //     data: member
        // } 
        const {data} = await axios.post(BASE_URL+`memberlist_webapi.php?user_id=${user_id}`)

        
        dispatch({type : 'getMemberSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'getMemberFail',payload : error.response.data.message});
    }
}

export const toggleGroup = (value) => (dispatch) => {
    try {
      dispatch({ type: 'toggleGroupRequest' });
  
      // Simulate setting the state without an API call
      const newState = {
        toggle : value,
      };
  
      dispatch({ type: 'toggleGroupSuccess', payload: newState });
    } catch (error) {
      dispatch({ type: 'toggleGroupFail', payload: error.response.data.message });
    }
  };
  export const getGroupPackage = (user_id) => async (dispatch)=>{

    try {
        dispatch({type : 'getGroupPackageRequest'});
 
        const {data} = await axios.get(BASE_URL+`memberpackage_webapi.php?user_id=${user_id}`)

        
        dispatch({type : 'getGroupPackageSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'getGroupPackageFail',payload : error.response.data.message});
    }
}
export const undefineGroupPackage = () => async (dispatch)=>{

        dispatch({type : 'undefineGroupPackage'  });
 
}
  