import { BASE_URL } from '../../constants/constants';
// import {server} from '../store'
import axios from 'axios'


export const login = (user_email, user_password) => async (dispatch)=>{
    try {
        dispatch({type : 'loginRequest'});
        const {data} = await axios.post(BASE_URL+'signin_webapi.php', {user_email , user_password} , {
            headers:{
                'Content-Type': "multipart/form-data"
            }
        })
        dispatch({type : 'loginSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'loginFail',payload : error.response.data.message});
    }
}
export const logOut = () => async (dispatch)=>{
    try {
        dispatch({type : 'logOutRequest'});
        const data = {
            status : 'true',
            message :'Logged Out Successfully'
        }
        dispatch({type : 'logOutSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'logOutFail',payload : error.response.data.message});
    }
}
export const signUp = ({user_email, user_password, user_name , user_mobile}) => async (dispatch)=>{
    try {
        dispatch({type : 'signUpRequest'});
        const {data} = await axios.post(BASE_URL+`signup_webapi.php`, {user_email , user_password , user_name , user_mobile} , {
            headers:{
                'Content-Type': "multipart/form-data"
            }
        })
        dispatch({type : 'signUpSuccess' , payload : data });
    } catch (error) {
        dispatch({type : 'signUpFail',payload : error.response.data.message});
    }
}